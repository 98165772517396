import { Button, Col, Container, Form, Row, Toast } from "react-bootstrap";
import Map from "../Modules/Map";
import Turnstile, { useTurnstile } from "react-turnstile";
import axios, { AxiosInstance } from "axios";
import { useState } from "react";

export default function Contact() {
	var client = axios.create({
		baseURL: "https://earsonly.nl/",
		headers: {
			"Content-Type": "application/json",
		},
	});

	const [token, setToken] = useState<string>();
	const turnstile = useTurnstile();
	const [sentEmail, setSentEmail] = useState<boolean>(false);
	const [validated, setValidated] = useState<boolean>(false);

	function TurnstileWidget() {
		return (
			<Turnstile
				className="right"
				language={"nl-nl"}
				sitekey="0x4AAAAAAAcCPsZTmbeezMyz"
				theme="light"
				action="contact"
				refreshExpired="auto"
				appearance="always"
				onVerify={(token) => {
					setToken(token);
				}}
			/>
		);
	}

	function handleSubmit(event: any) {
		event.preventDefault();
		const etf = event.target.form;
		setValidated(!etf.checkValidity());

		if (token && etf.checkValidity()) {
			client
				.post("/api/contact", {
					name: etf.name.value,
					email: etf.email.value,
					subject: etf.subject.value,
					message: etf.message.value,
					"cf-turnstile-response": token,
				})
				.then((response) => {
					setSentEmail(true);
					console.error("response", response);
				})
				.catch((error) => {
					setSentEmail(false);
					setValidated(false);
					console.error("error", error);
				});
		} else if (etf.checkValidity()) {
			console.error("Token missing");
			turnstile.reset();
		}
		setToken(undefined);
	}

	return (
		<>
			<Container className="container-padding-top">
				<h1>Contact</h1>
				<Row className="contact-form">
					<Col>
						<Form validated={validated}>
							<Row>
								<Col>
									<Form.Control
										disabled={sentEmail}
										required
										placeholder="Naam"
										id="name"
										type="name"
									/>
									<Form.Control.Feedback type="invalid">Uw naam ontbreekt.</Form.Control.Feedback>
								</Col>
								<Col>
									<Form.Control
										disabled={sentEmail}
										required
										placeholder="Emailadres"
										id="email"
										type="email"
									/>
									<Form.Control.Feedback type="invalid">Vul een geldig emailadres in zodat wij kunnen reageren op uw bericht.</Form.Control.Feedback>
								</Col>
							</Row>
							<br />
							<Row>
								<Col>
									<Form.Control
										disabled={sentEmail}
										required
										placeholder="Onderwerp"
										id="subject"
										type="input"
									/>
									<Form.Control.Feedback type="invalid">Vul een onderwerp in zodat wij uw bericht zo snel mogelijk kunnen beantwoorden.</Form.Control.Feedback>
								</Col>
							</Row>
							<br />
							<Form.Control
								required
								disabled={sentEmail}
								id="message"
								className="text-area"
								placeholder="Uw bericht"
								as="textarea"
								type="text"
								maxLength={500}
								minLength={15}
								rows={4}
							></Form.Control>
							<Form.Control.Feedback type="invalid">Schrijf een bericht tussen de 15-500 karakters.</Form.Control.Feedback>

							<br />
							{token && !sentEmail && (
								<Button
									className="button right"
									onClick={handleSubmit}
								>
									Verstuur
								</Button>
							)}
							{!token && !sentEmail && <TurnstileWidget />}
							{sentEmail && (
								<Button
									className="button right"
									disabled
								>
									Verstuurd
								</Button>
							)}
							<br />
							<br />
							<br />
							<br />
						</Form>
					</Col>
					<Col>
						<Map />
					</Col>
				</Row>
			</Container>
		</>
	);
}
