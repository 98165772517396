import { Button, Container, Image } from "react-bootstrap";
import * as Icon from "react-bootstrap-icons";

export default function PageDoesNotExist() {
	return (
		<Container className="container-padding-top page-not-found">
			<h1>404 Error</h1>
			<h2>Page not found</h2>
			<br />
			<br />
			<br />
			<br />
			<Button
				className="fw-bold"
				href="/home"
				size="lg"
			>
				<Icon.CaretLeftFill /> Go to the homepage
			</Button>
		</Container>
	);
}
