import * as React from "react";
import "../index.css";
import "../Modules/protect/protect.css";
import { Col, Container, Image, Row } from "react-bootstrap";
import Highlights from "../Modules/protect/Highlights";
import Product from "../Modules/protect/Product";
import AudioShowcase from "../Modules/protect/AudioShowcase";
import ImageHighlights from "../Modules/protect/ImageHighlights";
import Testimonials from "../Modules/protect/testimonials";
import AppShowcase from "../Modules/protect/AppShowcase";
import Process from "../Modules/protect/Process";

export default function Protect() {
	return (
		<>
			<Image
				className="d-none d-sm-block product-hero"
				src="images/HeaderImage.jpg"
				alt="Bouwvakker met EarsOnly Earbud in oor"
				fluid
			></Image>
			<Image
				className="d-block d-sm-none product-hero"
				src="images/Worker_with_protect.jpg"
				alt="Bouwvakker met EarsOnly Earbud in oor"
				fluid
			></Image>
			<Container className="container-padding-bottom">
				<Product />
				<Highlights />
				<AppShowcase />
				<ImageHighlights />
				<Process />
				<Testimonials />
			</Container>
		</>
	);
}
